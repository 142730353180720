var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a7adc87dca427b8aec02fa14b3bb90fe85bdcf48"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

import { publicEnv } from "@chapter/next-env";

const env = publicEnv();
const environment = (env.NEXT_PUBLIC_APP_ENV ?? "development").toLowerCase();
Sentry.init({
    dsn: "https://9548289f27da92865e578a52d54ed8f2@o1009884.ingest.sentry.io/4505755209629696",
    // TODO: adjust this value in production
    tracesSampleRate: 1,
    enabled: environment !== "development",
    release: env.NEXT_PUBLIC_APP_VERSION,
    debug: false,
    environment,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    replaysSessionSampleRate: 0, // Don't screen record outside of errors
    replaysOnErrorSampleRate: 1,
    ignoreErrors: [
        "Can't find variable: gmo", // https://issues.chromium.org/issues/396043331
    ],
    tracePropagationTargets: [/^https:\/\/([\w\d\-]+\.)*askchapter\.org/, /localhost:\d+/],
});
